.head1{
    text-align: justify;
}
.join{
    background-color: #00BFA6;
    border-radius: 3vw;
   
}
/* .section2{
    background-color:#F7F7F7;
    /* height: 24vw; */
/* }  */
.logo{
    cursor: pointer;
}
img{
    border-radius: .7vw
}
.but{
    background-color: #00BFA6;
    color: whitesmoke;
    border-radius: 4vw;
}
.mob{
    border: none;
    outline: none;
    border-bottom: 1px solid black;
}
.link{
    color: black;
    text-decoration: none;
}
.nav-link{
    color: black;
}
.nav-link:hover{
    color: black;
}
h4{
    text-decoration: underline;
}
.more{
    cursor: pointer;
}
.foot{
    background-color: #D9D9D9;
}
.foun{
    height: 5vw;
}
.ji{
    color: white;
}

.card h1{
    font-weight: 800;
    text-align: center;
}
.let
{
    font-size: 4vw;
}
.courses{
    background-image: url("../resources/bg.jpg");
}

.core{
    border-top-right-radius: 2vw;
    border-bottom-left-radius: 2vw;
    /* border-color: 12px solid #fff; */
}
.core:hover{
    background-color: #008CCA;
}
.head{
    color: #fff;
}


.lin{
/* color: rgba(0,0,0,.87);
border-bottom-color: rgba(0,0,0,.42); */
font-size: 1rem;
font-weight: 400;
letter-spacing: .009375em;
text-decoration: inherit;
text-transform: inherit;
align-self: flex-end;
outline: none;
/* box-sizing: border-box; */
padding: 20px 16px 6px;
/* transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1); */
border: none;
box-shadow: none;
border-bottom: 1px solid;
border-radius: 4px 4px 0 0;
/* background: rgb(245, 245, 245); */
height: 56px;

}

.bit{
    color: white;
    background-color: #002D68;
    border-radius: 2vw;
}
.quotes{
    color: #757575;
}

.info{
    color:#384F9F;
}
.founder{
    border: 2px solid black;
}
.back
{
    background-color: #002D68;
}