.img{
    height: 33.5vw;
    width: 25vw;
}
.navlink:hover
{
    color: black;
}
.foot{
    background-color: #D9D9D9;
}